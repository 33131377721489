<script>
import App from '@/Pages/App.vue'
import HeroSection from '@/Pages/Site/Home/HeroSection/HeroSection.vue'
import Button from '@/Components/Button/Button.vue';
import {ref} from 'vue';
import * as func from '@/Helpers/functions';

import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";

export default {
    components: {
        App,
        HeroSection,
        Button,
        InfiniteLoading
    },
    props: {
        rafflesHighlight: Object,
        rafflesOuther: Object,
        rafflesFinish: Object
    },
    data() {
        return {}
    },
    methods: {
        /*filteredRafflesStatus(status, highlight) {
            let tempRaffles = this.raffles

            console.log(status)

            tempRaffles = tempRaffles.filter((item) => {
                return (item.status === status && item.highlight === highlight)
            })

            return tempRaffles;
        }*/
    },
    // mounted() {
    //     /*console.log(this.destaques)
    //     console.log(this.ativas)
    //     console.log(this.finalizadas)
    //     console.log(this.raffles)

    //     this.destaques = this.filteredRafflesStatus('Ativo', true)
    //     this.ativas = this.filteredRafflesStatus('Ativo', false)*/
    // },
    setup(props) {
       //console.log(props.rafflesFinish)
        /* console.log('aqui')*/

      // let finish = ref(props.rafflesFinish);

        const finish = ref([]);

        finish.value.push(...props.rafflesFinish.data)

        // console.log(finish)

        let page = 2;
        const nextPage = ref('?page=' + page);
        const load = async $state => {
            console.log("loading...");
            try {
                const response = await fetch(nextPage).then(r => r);

                // const json = response.body;
                // console.log(response);
                // const json = await response;
                if (response.length < 10) $state.complete();
                else {
                    finish.value.push(...response);

                    // console.log(finish);
                    $state.loaded();
                }
                page++;
            } catch (error) {
                console.log(error)
                $state.error();
            }
        };

        return {
            load,
            finish,
            page,
            func
        };
    },
}
</script>

<template>
    <App>
        <HeroSection :highlight="rafflesHighlight"/>

        <section v-if="rafflesOuther && (rafflesOuther?.length > 0)" class="pt-10 pb-5">
            <div class="container">
                <h2 class="o-title">Próximos Sorteios</h2>

                <div class="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4">
                    <template v-for="(item, index) in rafflesOuther" :key="index">
                        <a :href="route('raffle', item.link)" class="c-card__item">
                            <figure class="overflow-hidden aspect-square">
                                <img :src="item.raffle_images[0]?.thumb"
                                     class="object-cover w-full h-full rounded-xl" :alt="item.title">
                            </figure>
                            <div class="flex flex-col justify-between flex-1">
                                <p class="leading-tight text-neutral/60">{{ item.title }}</p>
                                <p class="text-xl font-bold text-neutral">{{ func.formatValue(item.price) }}</p>
                                <Button type="button" color="primary" class="mt-2">Clique e Participe</Button>
                            </div>
                        </a>
                    </template>
                </div>
            </div>
        </section>

        <section v-if="finish && (finish?.length > 0)" id="drawn" class="py-5" >
            <div class="container">
                <h2 class="o-title">Últimos Sorteios</h2>

                <div class="grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4">
                    <template v-for="(item, index) in finish" :key="index">
                        <a :href="route('raffle', item.link)" class="c-card__item">
                            <figure>
                                <img :src="item.raffle_images[0]?.thumb"
                                     class="object-cover w-full h-full" :alt="item.title">
                            </figure>
                            <div class="flex flex-col justify-between flex-1 gap-1">
                                <p class="flex-1 text-lg font-normal leading-tight text-neutral line-clamp-2" :title="item.title">{{ item.title }}</p>

                                <p class="text-neutral text-neutral/70" v-if="item.raffle_awards[0]?.number_award">
                                    Sorteado: <span class="font-bold text-neutral">N° {{item.raffle_awards[0]?.number_award}}</span>
                                </p>

                                <p class="leading-tight text-neutral/70" v-if="item.raffle_awards[0]?.winner_name">
                                    Ganhador:
                                    <span class="font-bold text-neutral">{{item.raffle_awards[0]?.winner_name}}</span>
                                </p>

                                <Button type="button" color="primary" class="mt-2">Ver Resultado</Button>
                            </div>

                            <span class="c-closed">Encerrado</span>
                        </a>
                    </template>
                </div>
            </div>
        </section>

        <InfiniteLoading @infinite="load">
            <template #complete><span></span></template>
            <template #error><span></span></template>
        </InfiniteLoading>
    </App>
</template>

<style lang="scss" scoped>
.c-card__item {
    @apply flex flex-col gap-4 border border-base-100 bg-content p-4 rounded-2xl relative overflow-hidden;

    figure{
        @apply border border-base-100/50 aspect-square overflow-hidden rounded-xl;

        img{
            transition: .2s ease-in-out;
        }
    }

    &:hover{
        figure{
            img{
                transform: scale(1.03);
            }
        }
    }
}

.o-title {
    @apply flex text-2xl font-bold text-neutral items-center gap-3 mb-2;

    &:after {
        content: '';
        @apply h-[1px] flex-1 mt-1;
    }
}

.c-closed{
    @apply py-0.5 px-8 text-error-bw bg-error font-semibold absolute origin-center top-5 -left-8 -rotate-45;
}
</style>
